<template>
    <a-button @click="handleClick">导出</a-button>
</template>
<script>
    import Message from "@/Plugin/MessageHelper.js";
    export default {
        name: "",
        data() {
            return {
            };
        }, 
        props: {//组件属性
        },
        methods: {//方法
            handleClick() {
                Message.error("aaaaaaaaaaaaa");
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>